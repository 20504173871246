import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { FaArrowRight } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleSubmitOnlie = async (event) => {
    navigate("/THGOA");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(loginForm);
    if (loginForm.email !== "" && loginForm.password !== "") {
      try {
        const response = await fetch("https://fsrvr1.com/falcon_thgoa/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginForm),
        });

        const data = await response.json();
        const admin_id = localStorage.setItem("admin_id",data.data[0].admin_mail);
        console.log(data);
        if (response.ok) {
          swal({
            title: data.message,
            icon: "success",
            dangerMode: true,
          });
          navigate("/SideBar/DashBoard");
        } else {
          swal({
            title: data.message,
            icon: "error",
            dangerMode: true,
          });
        }
      } catch {
        swal({
          title: "An error occurred",
          text: "Please try again later",
          icon: "error",
          dangerMode: true,
        });
      }
    } else {
      swal({
        title: "Please Enter Your credentials",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  return (
    <div className="main">
      <div className="login-card">
        <h3 className="title text-center">Admin Login</h3>

        <form>
          <div>
            <label className="label">Email:</label>
            <input
              type="text"
              name="email"
              value={loginForm.email}
              onChange={handleInputChange}
              required
            />
                       
          </div>
          <div>
            <label className="label">Password:</label>
            <input
              type="password"
              name="password"
              value={loginForm.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <button className="login-btn mb-3" onClick={handleSubmit}>
              Login
            </button>
          </div>
        </form>
        <p className="login-content p-2  m-0">Click the below link to apply online</p>
        <a className="login-btn-online" onClick={handleSubmitOnlie}>
          Online Registration Form  <FaArrowRight className="arrow" />
        </a>
      </div>
    </div>
  );
}

export default Login;
