import React, { useState, useEffect } from "react";
import "./OnlineRegister.css";
import { FaCross, FaPlus, FaSearch } from "react-icons/fa";
import swal from "sweetalert";
import img from "../Images/logo.png";
import img1 from "../Images/logo1.png";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Form from "react-bootstrap/Form";
import qr from "../Images/qrscanner.jpg";
import Select from "react-select";
function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}

const options = [
  {
    id: "1",
    name: "Tiruvallur",
    secname: "TIR_",
  },
  {
    id: "2",
    name: "Vellore",
    secname: "VLR_",
  },
  {
    id: "3",
    name: "Ranipet",
    secname: "RPT_",
  },
  {
    id: "4",
    name: "Kanchipuram",
    secname: "KNC_",
  },
  {
    id: "5",
    name: "Chengalpattu",
    secname: "CGL_",
  },
  {
    id: "6",
    name: "Krishnagiri",
    secname: "KRI_",
  },
  {
    id: "7",
    name: "Tirupattur",
    secname: "TPT_",
  },
  {
    id: "8",
    name: "Tiruvannamalai",
    secname: "TVM_",
  },
  {
    id: "9",
    name: "Villupuram",
    secname: "VLR_",
  },
  {
    id: "10",
    name: "Dharmapuri",
    secname: "DPI_",
  },
  {
    id: "11",
    name: "Kallakurichi",
    secname: "KKC_",
  },
  {
    id: "12",
    name: "Pudicherry",
    secname: "PY_",
  },
  {
    id: "13",
    name: "Cuddalore",
    secname: "CDL_",
  },
  {
    id: "14",
    name: "Salem",
    secname: "SLM_",
  },
  {
    id: "15",
    name: "Nilgiris",
    secname: "NIL_",
  },
  {
    id: "16",
    name: "Erode",
    secname: "ERD_",
  },
  {
    id: "17",
    name: "Namakkal",
    secname: "NML_",
  },
  {
    id: "18",
    name: "Perambalur",
    secname: "PMR_",
  },
  {
    id: "19",
    name: "Ariyalur",
    secname: "ALR_",
  },
  {
    id: "20",
    name: "Mayiladuthurai",
    secname: "MAD_",
  },
  {
    id: "21",
    name: "Kanniyakumari",
    secname: "KK_",
  },
  {
    id: "22",
    name: "Thiruchirapalli",
    secname: "TIR_",
  },
  {
    id: "23",
    name: "Karur",
    secname: "KR_",
  },
  {
    id: "24",
    name: "Coimbatore",
    secname: "CBE_",
  },
  {
    id: "25",
    name: "Thanjavur",
    secname: "TJ_",
  },
  {
    id: "26",
    name: "Tiruvarur",
    secname: "TVR_",
  },
  {
    id: "27",
    name: "Nagapattinam",
    secname: "NAG_",
  },
  {
    id: "28",
    name: "Tiruppur",
    secname: "TUP_",
  },
  {
    id: "29",
    name: "Pudukkottai",
    secname: "PKT_",
  },
  {
    id: "30",
    name: "Dindugal",
    secname: "DG_",
  },
  {
    id: "31",
    name: "Theni",
    secname: "TNI_",
  },
  {
    id: "32",
    name: "Sivagangi",
    secname: "SGI_",
  },
  {
    id: "33",
    name: "Madurai",
    secname: "MDU_",
  },
  {
    id: "34",
    name: "Virudhunagar",
    secname: "VNR_",
  },
  {
    id: "35",
    name: "Thenkasi",
    secname: "TKS_",
  },
  {
    id: "36",
    name: "Ramanathapuram",
    secname: "RMT_",
  },
  {
    id: "37",
    name: "Thoothukudi",
    secname: "TUTI_",
  },
  {
    id: "38",
    name: "Tirunalveli",
    secname: "TEN_",
  },
];
const optionList = [
  { value: "1", label: "sound and light" },
  { value: "2", label: "Genset" },
  { value: "3", label: "shamiana" },
  { value: "4", label: "samayal paathirangal" },
  { value: "5", label: "super kattamaippu pantal" },
  { value: "6", label: "PVC Pantal" },
  { value: "7", label: "Keettru Pantal" },
  { value: "8", label: "Decoration" },
  { value: "9", label: "Malar alaṅkāram" },
  { value: "10", label: "Furniture" },
  { value: "11", label: " Pachai Pantal" },
  { value: "12", label: "GI Pantal" },
];
const bloodGroup = [
  {
    blood: "A+",
  },
  {
    blood: "A-",
  },
  {
    blood: "B+",
  },
  {
    blood: "B-",
  },
  {
    blood: "O+",
  },
  {
    blood: "O-",
  },
  {
    blood: "AB+",
  },
  {
    blood: "AB-",
  },
];

const OnlineRegister = () => {
  const navigate = useNavigate();
  const [memberName, setMemberName] = useState("");
  const [address, setAddress] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [district, setDistrict] = useState("");
  const [webAddress, setwebAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyYear, setCompanyyear] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [employee, setEmployee] = useState("");
  const [employeeTemp, setEmployeeTemp] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [Taluk, setTaluk] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [memberName1, setMemberName1] = useState("");
  const [memberNameValid1, setMemberNameValid1] = useState(true);
  const [showGSTINInput, setShowGSTINInput] = useState(false);
  const [memberNameValid, setMemberNameValid] = useState(true);
  const [companyvalid, setcompanyValid] = useState(true);
  const [addressValid, setAddressValid] = useState(true);
  const [locationvalid, setlocationValid] = useState(true);

  const [employeevalid, setEmployeeValid] = useState(true);
  const [emailnameValid, setEmailNameValid] = useState(true);
  const [webvalid, setWebValid] = useState(true);
  const [employeevalidTemp, setEmployeeValidTemp] = useState(true);
  const [referenceNumberValid, setReferenceNumberValid] = useState(true);
  const [TalukValid, setTalukValid] = useState(true);
  const [memberid, setMemberId] = useState("");
  const [districValid, setdistricValid] = useState(true);
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);
  const [hide2, setHide2] = useState(true);
  const [hide3, setHide3] = useState(true);
  const [gstvalid, setgstValid] = useState(true);
  const generateRandomHash = () => {
    const randomString = generateRandomString(10);
    return randomString;
  };

  const [fileValid, setFileValid] = useState(true);
  const [fileValid1, setFileValid1] = useState(true);

  const [fileValid2, setFileValid2] = useState(true);
  const [file, setFile] = useState(null);

  const [fileValid3, setFileValid3] = useState(true);
  const [fileValid4, setFileValid4] = useState(true);

  const [formData1, setFormData1] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file2: null,
    state: "0",
  });
  const [formData2, setFormData2] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file1: null,
  });
  const [formData3, setFormData3] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file3: null,
    state: "0",
  });
  const [formData4, setFormData4] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file4: null,
    state: "0",
  });
  const [formErrors, setFormErrors] = useState({
    companyMember: false,
    aadharNumber: false,
    phoneNumber: false,
    alternativeNumber: false,
    age: false,
    referenceNumber: false,
  });
  const [formErrors1, setFormErrors1] = useState({
    companyMember: false,
    aadharNumber: false,
    phoneNumber: false,
    alternativeNumber: false,
    age: false,
    referenceNumber: false,
  });
  const [formErrors3, setFormErrors3] = useState({
    companyMember: false,
    aadharNumber: false,
    phoneNumber: false,
    alternativeNumber: false,
    age: false,
    referenceNumber: false,
  });
  const [formErrors4, setFormErrors4] = useState({
    companyMember: false,
    aadharNumber: false,
    phoneNumber: false,
    alternativeNumber: false,
    age: false,
    referenceNumber: false,
  });
   const handleSelect = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.label);
    console.log("Selected labels:", selectedLabels);
    setSelectedService(selectedLabels);
  };
   const validateForm = (formErrors) => {
    return Object.values(formErrors).some((error) => error);
  };

  const validateMemberName = (name) => {
    return name.trim() !== "";
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validategst = (gst) => {
    const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    return gstRegex.test(gst);
  };
  const validateRefernce = (name) => {
    return name.length === 10;
  };
  const validateYear = (gst) => {
    const gstRegex = /^\d{4}$/;
    return gstRegex.test(gst);
  };
  const validateEmployee = (employee) => {
    return employee.trim() !== '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      const hasValidationErrors = validateForm(formErrors);
    const hasValidationErrors1 = validateForm(formErrors1);
    const hasValidationErrors2 = validateForm(formErrors4);
    const hasValidationErrors3 = validateForm(formErrors3);
    const validateEmailResult = validateEmail(emailAddress);
    const validateReferenceResult = validateRefernce(referenceNumber);
    // const validategstResult = validategst(gstNo);
    const validatMemberResult = validateMemberName(memberName);
    const validatMember1Result = validateMemberName(memberName1);
    const validatAddressResult = validateMemberName(address);
    const validatwebResult = validateMemberName(webAddress);
    const validatThalukResult = validateMemberName(Taluk);
    const validatLocationResult = validateMemberName(locationValue);
    const validatYearResult = validateYear(companyYear);
    const validatPermanentResult = validateEmployee(employee);
    const validatTemporaryResult = validateEmployee(employeeTemp);

    if (
      hasValidationErrors ||
      hasValidationErrors1 ||
      hasValidationErrors2 ||
      hasValidationErrors3 ||
      !validateEmailResult ||
      !validateReferenceResult ||
      // !validategstResult ||
      !validatMemberResult ||
      !validatMember1Result ||
      !validatAddressResult ||
      !validatwebResult ||
      !validatThalukResult ||
      !validatLocationResult ||
      !validatYearResult ||
      !validatPermanentResult ||
      !validatTemporaryResult
    ) {
      swal({
        title: "Please fix the validation errors.",
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    const randomHashcode = generateRandomHash();
    var date = new Date();
    var getYear = date.toLocaleString("default", { year: "numeric" });
    var getMonth = date.toLocaleString("default", { month: "2-digit" });
    var getDay = date.toLocaleString("default", { day: "2-digit" });
    var dateformat = getYear + "-" + getMonth + "-" + getDay;
    // const url1 = "http://localhost:3005/member_details";
    const url = "https://fsrvr1.com/falcon_thgoa/create";

    const formData = new FormData();

    // Append data to formData here
    formData.append("company_id", memberid);
    formData.append("gstNo", gstNo);
    formData.append("payment", "0");
    formData.append("member_due_date", "0");
    formData.append("member_status", "0");
    formData.append("entery", "Online");
    formData.append("companyYear", companyYear);
    formData.append("locationValue", locationValue);
    formData.append("memberName", memberName);
    formData.append("memberName1", memberName1);
    formData.append("employee", employee);
    formData.append("inputValue", selectedService);
    formData.append("web", webAddress);
    formData.append("employeeTemp", employeeTemp);
    formData.append("referenceNumber", referenceNumber);
    formData.append("taluk", Taluk);
    formData.append("district", district);
    formData.append("address", address);
    formData.append("emailAddress", emailAddress);
    formData.append("shop", selectedOption);
    formData.append("file", file);
    formData.append("file2", formData1.file2);
    formData.append("memberid", memberid);
    formData.append("age1", formData2.age);
    formData.append("aadharNumber1", formData2.aadharNumber);
    formData.append("alternativeNumber1", formData2.alternativeNumber);
    formData.append("blood1", formData2.blood);
    formData.append("companyMember1", formData2.companyMember);
    formData.append("dateOfBirth1", formData2.dateOfBirth);
    formData.append("education1", formData2.education);
    formData.append("phoneNumber1", formData2.phoneNumber);
    formData.append("file1", formData2.file1);
    formData.append("file3", formData3.file3);
    formData.append("file4", formData4.file4);
    const additionalData = {
      age: formData1.age,
      aadharNumber: formData1.aadharNumber,
      alternativeNumber: formData1.alternativeNumber,
      blood: formData1.blood,
      companyMember: formData1.companyMember,
      dateOfBirth: formData1.dateOfBirth,
      education: formData1.education,
      phoneNumber: formData1.phoneNumber,
      state: formData.state,
    };
    const additionalData1 = {
      age: formData3.age,
      aadharNumber: formData3.aadharNumber,
      alternativeNumber: formData3.alternativeNumber,
      blood: formData3.blood,
      companyMember: formData3.companyMember,
      dateOfBirth: formData3.dateOfBirth,
      education: formData3.education,
      phoneNumber: formData3.phoneNumber,
      state: formData.state,
    };
    const additionalData2 = {
      age: formData4.age,
      aadharNumber: formData4.aadharNumber,
      alternativeNumber: formData4.alternativeNumber,
      blood: formData4.blood,
      companyMember: formData4.companyMember,
      dateOfBirth: formData4.dateOfBirth,
      education: formData4.education,
      phoneNumber: formData4.phoneNumber,
      state: formData.state,
    };

    formData.append("additionalData", JSON.stringify(additionalData));
    formData.append("additionalData1", JSON.stringify(additionalData1));
    formData.append("additionalData2", JSON.stringify(additionalData2));

    if (memberName !== "" && webAddress !== "") {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const responseData = await response.json();

        if (response.ok) {
          swal({
            title: responseData.message,
            icon: "success",
            dangerMode: true,
          });
           handleReload();
          //navigate("/dash/member");
        }else {
                 
                        swal({
                            title: "An error occurred",
                            text: "Please try again later",
                            icon: "error",
                            dangerMode: true,
                          });
             
                }
            } catch (error) {
                swal({
                    title: "An error occurred",
                    text: "Please try again later",
                    icon: "error",
                    dangerMode: true,
                  });
            }
        } else {
            swal({
                title: "Please Enter Your credentials",
                icon: "error",
                dangerMode: true,
            });
        }
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "gstNo") {
      setShowGSTINInput(true);
    } else {
      setShowGSTINInput(false);
      setGstNo("");
    }
  };
  const handleInputBlood = (e) => {
    const selectedOptionName = e.target.value;
    setFormData2({ ...formData2, blood: selectedOptionName });
  };
  const handleInputBlood1 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData1({ ...formData1, blood: selectedOptionName });
  };
  const handleInputBlood3 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData3({ ...formData3, blood: selectedOptionName });
  };
  const handleInputBlood4 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData4({ ...formData4, blood: selectedOptionName });
  };
  const handleReload = () => {
    
    window.location.reload();
  };

 
  const handleDistrictChange = (e) => {
    const selectedOptionName = e.target.value;
    setDistrict(selectedOptionName);
    const selectedOption = options.find(
      (option) => option.name === selectedOptionName
    );
    if (selectedOption) {
      const randomNum = Math.floor(Math.random() * 100);
      const updatedSecName = `${selectedOption.secname}${randomNum}`;
      setMemberId(updatedSecName);
    }
    setdistricValid(selectedOptionName !== "");
  };

  const handleRadioChange1 = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData2({
      ...formData2,
      [name]: value,
    });

    if (name === "aadharNumber") {
      const regex = /^\d{12}$/;
      const isValidPhoneNumber = regex.test(value);
      setFormErrors({
        ...formErrors,
        [name]:!isValidPhoneNumber
      });
    }
    if (name === "phoneNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors({
        ...formErrors,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "alternativeNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors({
        ...formErrors,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "age") {
      const ageValue = parseInt(value, 10);
      const isValidAge = !isNaN(ageValue) && ageValue >= 10 && ageValue <= 100;
      setFormErrors({
        ...formErrors,
        [name]: !isValidAge,
      });
    }
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      [name]: value,
    });

    if (name === "aadharNumber") {
      const regex = /^\d{12}$/;
      const isValidPhoneNumber = regex.test(value);
      setFormErrors1({
        ...formErrors1,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "phoneNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors1({
        ...formErrors1,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "alternativeNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors1({
        ...formErrors1,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "age") {
      const ageValue = parseInt(value, 10);
      const isValidAge = !isNaN(ageValue) && ageValue >= 10 && ageValue <= 100;
      setFormErrors1({
        ...formErrors1,
        [name]: !isValidAge,
      });
    }
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setFormData3({
      ...formData3,
      [name]: value,
    });

    if (name === "aadharNumber") {
      const regex = /^\d{12}$/;
      const isValidPhoneNumber = regex.test(value);
      setFormErrors3({
        ...formErrors3,
        [name]: !isValidPhoneNumber
      });
    }
    if (name === "phoneNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors3({
        ...formErrors3,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "alternativeNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors3({
        ...formErrors3,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "age") {
      const ageValue = parseInt(value, 10);
      const isValidAge = !isNaN(ageValue) && ageValue >= 10 && ageValue <= 100;
      setFormErrors3({
        ...formErrors3,
        [name]: !isValidAge,
      });
    }
  };
  const handleInputChange4 = (e) => {
    const { name, value } = e.target;
    setFormData4({
      ...formData4,
      [name]: value,
    });

    if (name === "aadharNumber") {
      const regex = /^\d{12}$/;
      const isValidPhoneNumber = regex.test(value);
      setFormErrors4({
        ...formErrors4,
        [name]: !isValidPhoneNumber
      });
    }
    if (name === "phoneNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors4({
        ...formErrors4,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "alternativeNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors4({
        ...formErrors4,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "age") {
      const ageValue = parseInt(value, 10);
      const isValidAge = !isNaN(ageValue) && ageValue >= 10 && ageValue <= 100;
      setFormErrors4({
        ...formErrors4,
        [name]: !isValidAge,
      });
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 150 * 1024) {
        setFileValid2(false);
      } else {
        setFileValid2(true);
      }
      setFormData2({
        ...formData2,
        file1: file,
      });
    }
  };
  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // 150 KB in bytes
        setFileValid1(false);
      } else {
        setFileValid1(true);
      }
      setFormData1({
        ...formData1,
        file2: file,
      });
    }
  };
  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 800 * 1024) {
        // 150 KB in bytes
        setFileValid(false);
      } else {
        setFileValid(true);
      }
      setFile(file);
    }
  };
  const handleFileChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // 150 KB in bytes
        setFileValid3(false);
      } else {
        setFileValid3(true);
      }
      setFormData3({
        ...formData1,
        file3: file,
      });
    }
  };
  const handleFileChange4 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 250 * 1024) {
        // 150 KB in bytes
        setFileValid4(false);
      } else {
        setFileValid4(true);
      }
      setFormData4({
        ...formData4,
        file4: file,
      });
    }
  };

  const handlehide = () => {
    setHide(false);
  };
  const handlehide1 = () => {
    setHide1(false);
  };
  const handlehide2 = () => {
    setHide2(false);
  };
  const handlehide3 = () => {
    setHide3(false);
  };

  const inputcmpname = () => {
    if (!memberNameValid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputcmpowner = () => {
    if (!memberNameValid1) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputClass = () => {
    if (!addressValid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputRefer = () => {
    if (!referenceNumberValid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputemail = () => {
    if (!emailnameValid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputweb = () => {
    if (!webvalid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputhaluk = () => {
    if (!TalukValid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputgst = (e) => {
    if (!gstvalid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputyear = () => {
    if (!companyvalid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputlocation = () => {
    if (!locationvalid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputpermanent = () => {
    if (!employeevalid) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  const inputemporary = () => {
    if (!employeevalidTemp) {
      return "form-control border border-danger"; // Apply red border when invalid
    } else {
      return "form-control"; // Default border color
    }
  };
  return (
    <div
      className="container "
      style={{
        backgroundColor: "#f5f5f5",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {" "}
      <div className="head-con">
        <div>
          {" "}
          <img src={img} className="member-logo1"></img>
        </div>
        <div>
          <h2>Thamizhaga Hire Goods Owners Association </h2>
          <h5>No:1, Jayachiman street,Avarampalayam,Coimbatore-641006</h5>
          <h3>Registration Form</h3>
          <h6>( Members only Allowed for Exhibition ) </h6>
        </div>
        <div>
          <img src={img1} className="member-logo2"></img>
        </div>
      </div>
      <div className="outer-con">
        <div className="head-form ">
          {/* <div className="login1-benten">
            <button
              type="submit"
              className="login-bttn"
              onClick={handlelogout}
            >
              {" "}
              Log Out
            </button>
          </div> */}
        </div>
        <form action="/create" method="post" encType="multipart/form-data">
          <div className="row mb-4">
            <div className="col-lg-12 head-container">
              <div className="row">
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label">
                    Company Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={inputcmpname()}
                    id="inputEmail4"
                    value={memberName}
                    onChange={(e) => {
                      setMemberName(e.target.value);
                      setMemberNameValid(e.target.value !== "");
                    }}
                    style={{ borderColor: memberNameValid ? "initial" : "red" }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label">
                    Company Owner <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={inputcmpowner()}
                    id="inputEmail4"
                    value={memberName1}
                    onChange={(e) => {
                      setMemberName1(e.target.value);
                      setMemberNameValid1(e.target.value !== "");
                    }}
                    style={{ borderColor: memberNameValid ? "initial" : "red" }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          {/*START ADD MEMBER     */}
          <div className="grid-container mt-4">
            <div className="col-lg-12 mb-5">
              <div className="fa-button d-flex justify-content-between">
                <span className="mem-fon">Person </span>
                <div>
                  <button
                    type="button"
                    className="sub-btn-add"
                    onClick={handlehide}
                  >
                    {" "}
                    <FaPlus style={{ marginRight: "5px" }} />
                    Add person
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-danger m-2 "
                    onClick={() =>setIsHidden(true) }
                  >
                    X
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Company Member <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.companyMember ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="companyMember"
                    value={formData2.companyMember}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Aadhar Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors.aadharNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="aadharNumber"
                    value={formData2.aadharNumber}
                    onChange={handleInputChange}
                    maxLength={12}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors.phoneNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="phoneNumber"
                    value={formData2.phoneNumber}
                    onChange={handleInputChange}
                    maxLength={10}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Alternative Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors.alternativeNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="alternativeNumber"
                    value={formData2.alternativeNumber}
                    onChange={handleInputChange}
                    maxLength={10}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    DOB <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputPassword4"
                    name="dateOfBirth"
                    value={formData2.dateOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Age <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.age ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="age"
                    value={formData2.age}
                    onChange={handleInputChange}
                    maxLength={3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Education <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors.education ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="education"
                    value={formData2.education}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Blood <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="district"
                    value={formData2.blood}
                    onChange={handleInputBlood}
                    style={{ borderColor: districValid ? "initial" : "red" }}
                    required
                  >
                    <option value="">Select a Blood Group</option>
                    {bloodGroup.map((option) => (
                      <option value={option.blood}>{option.blood}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="file1" className="form-label">
                    Upload File <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="file2"
                    className={`form-control ${fileValid2 ? "" : "is-invalid"}`}
                    id="myFile"
                    accept=".jpg, .jpeg, .png,"
                    onChange={handleFileChange}
                  />
                  {!fileValid2 && (
                    <div className="invalid-feedback">
                      File size must be less than or equal to 250KB.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          {!hide && (
            <div className="col-lg-12 ">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button type="button" className="sub-btn-add" onClick={handlehide1}>
                  {" "}
                  <FaPlus style={{ marginRight: "5px" }} />
                  Add person
                </button>
                <button
                  type="button"
                  className="btn btn-danger m-2 "
                  onClick={() => setHide(true)}
                >
                  X
                </button>
              </div>
              <div></div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Company Member <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors1.companyMember ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="companyMember"
                    value={formData1.companyMember}
                    onChange={handleInputChange1}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Aadhar Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors1.aadharNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="aadharNumber"
                    value={formData1.aadharNumber}
                    onChange={handleInputChange1}
                    maxLength={12}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors1.phoneNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="phoneNumber"
                    value={formData1.phoneNumber}
                    onChange={handleInputChange1}
                    maxLength={10}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Alternative Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors1.alternativeNumber
                        ? "border border-danger"
                        : ""
                    }`}
                    id="inputPassword4"
                    name="alternativeNumber"
                    value={formData1.alternativeNumber}
                    onChange={handleInputChange1}
                    required
                    maxLength={10}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    DOB <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputPassword4"
                    name="dateOfBirth"
                    value={formData1.dateOfBirth}
                    onChange={handleInputChange1}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Age <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors1.age ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="age"
                    value={formData1.age}
                    onChange={handleInputChange1}
                    required
                    maxLength={3}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Education <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors1.education ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="education"
                    value={formData1.education}
                    onChange={handleInputChange1}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Blood <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="district"
                    value={formData1.blood}
                    onChange={handleInputBlood1}
                    style={{ borderColor: districValid ? "initial" : "red" }}
                    required
                  >
                    <option value="">Select a Blood Group</option>
                    {bloodGroup.map((option) => (
                      <option value={option.blood}>{option.blood}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="file1" className="form-label">
                    Upload File <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="file1"
                    className={`form-control ${fileValid1 ? "" : "is-invalid"}`}
                    id="myFile"
                    accept=".jpg, .jpeg, .png,"
                    onChange={handleFileChange1}
                  />
                  {!fileValid1 && (
                    <div className="invalid-feedback">
                      File size must be less than or equal to 250KB.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!hide1 && (
            <div className="col-lg-12 ">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button type="button" className="sub-btn-add" onClick={handlehide3}>
                  {" "}
                  <FaPlus style={{ marginRight: "5px" }} />
                  Add person
                </button>
                <button
                  type="button"
                  className="btn btn-danger m-2 "
                  onClick={() => setHide1(true)}
                >
                  X
                </button>
              </div>
              <div></div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Company Member <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors3.companyMember ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="companyMember"
                    value={formData3.companyMember}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Aadhar Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors3.aadharNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="aadharNumber"
                    value={formData3.aadharNumber}
                    onChange={handleInputChange3}
                    maxLength={12}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors3.phoneNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="phoneNumber"
                    value={formData3.phoneNumber}
                    onChange={handleInputChange3}
                    maxLength={10}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Alternative Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors3.alternativeNumber
                        ? "border border-danger"
                        : ""
                    }`}
                    id="inputPassword4"
                    name="alternativeNumber"
                    value={formData3.alternativeNumber}
                    onChange={handleInputChange3}
                    maxLength={10}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    DOB <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputPassword4"
                    name="dateOfBirth"
                    value={formData3.dateOfBirth}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Age <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors3.age ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="age"
                    value={formData3.age}
                    onChange={handleInputChange3}
                    maxLength={3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Education <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors3.education ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="education"
                    value={formData3.education}
                    onChange={handleInputChange3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Blood <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="blood"
                    value={formData3.blood}
                    onChange={handleInputBlood3}
                    // style={{ borderColor: districValid ? "initial" : "red" }}
                    required
                  >
                    <option value="">Select a Blood Group</option>
                    {bloodGroup.map((option) => (
                      <option value={option.blood}>{option.blood}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="file1" className="form-label">
                    Upload File <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="file1"
                    className={`form-control ${fileValid3 ? "" : "is-invalid"}`}
                    id="myFile"
                    accept=".jpg, .jpeg, .png,"
                    onChange={handleFileChange3}
                  />
                  {!fileValid3 && (
                    <div className="invalid-feedback">
                      File size must be less than or equal to 250KB.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* <button
                    type="button"
                    className="btn btn-danger m-2 "
                    onClick={() =>setIsHidden(true) }
                  >
                    X
                  </button> */}
          {!hide3 && (
            <div className="col-lg-12 ">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  type="button"
                  className="btn btn-danger m-2 "
                  onClick={() => setHide3(true)}
                >
                  X
                </button>
              </div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Company Member <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors4.companyMember ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="companyMember"
                    value={formData4.companyMember}
                    onChange={handleInputChange4}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Aadhar Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors4.aadharNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="aadharNumber"
                    value={formData4.aadharNumber}
                    onChange={handleInputChange4}
                    maxLength={12}
                    required
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors4.phoneNumber ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="phoneNumber"
                    value={formData4.phoneNumber}
                    onChange={handleInputChange4}
                    maxLength={10}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Alternative Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors4.alternativeNumber
                        ? "border border-danger"
                        : ""
                    }`}
                    id="inputPassword4"
                    name="alternativeNumber"
                    value={formData4.alternativeNumber}
                    onChange={handleInputChange4}
                    required
                    maxLength={10}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    DOB <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputPassword4"
                    name="dateOfBirth"
                    value={formData4.dateOfBirth}
                    onChange={handleInputChange4}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Age <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${
                      formErrors4.age ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="age"
                    value={formData4.age}
                    onChange={handleInputChange4}
                    maxLength={3}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Education <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formErrors4.education ? "border border-danger" : ""
                    }`}
                    id="inputPassword4"
                    name="education"
                    value={formData4.education}
                    onChange={handleInputChange4}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="inputPassword4" className="form-label">
                    Blood <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    id="blood"
                    value={formData4.blood}
                    onChange={handleInputBlood4}
                    style={{ borderColor: districValid ? "initial" : "red" }}
                    required
                  >
                    <option value="">Select a Blood Group</option>
                    {bloodGroup.map((option) => (
                      <option value={option.blood}>{option.blood}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="file1" className="form-label">
                    Upload File <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="file4"
                    className={`form-control ${fileValid4 ? "" : "is-invalid"}`}
                    id="myFile"
                    accept=".jpg, .jpeg, .png,"
                    onChange={handleFileChange4}
                  />
                  {!fileValid4 && (
                    <div className="invalid-feedback">
                      File size must be less than or equal to 250KB.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-12  col-md-12 col-sm-12 mt-10">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mb-3 ">
                <label for="inputAddress" className="form-label">
                  Address <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  type="text"
                  className={inputClass()}
                  id="inputAddress"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setAddressValid(e.target.value !== "");
                  }}
                  style={{ borderColor: addressValid ? "initial" : "red" }}
                  required
                />
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 mb-3">
                <label for="inputPassword4" className="form-label">
                  Reference Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="tel"
                  inputMode="numeric"
                  className={inputRefer()}
                  id="inputPassword4"
                  value={referenceNumber}
                  onChange={(e) => {
                    setReferenceNumber(e.target.value);
                    setReferenceNumberValid(e.target.value.length === 10);
                  }}
                  maxLength={10}
                  style={{
                    borderColor: referenceNumberValid ? "initial" : "red",
                  }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6   col-sm-6  mb-3">
                <label for="inputEmail4" className="form-label">
                  Email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className={inputemail()}
                  id="inputEmail4"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                    setEmailNameValid(validateEmail(e.target.value));
                  }}
                  style={{ borderColor: emailnameValid ? "initial" : "red" }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6  col-sm-6  mb-3">
                <label for="inputPassword4" className="form-label">
                  Web <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className={inputweb()}
                  id="inputPassword4"
                  value={webAddress}
                  onChange={(e) => {
                    setwebAddress(e.target.value);
                    setWebValid(e.target.value !== "");
                  }}
                  style={{ borderColor: webvalid ? "initial" : "red" }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6   col-sm-6  mb-3">
                <label for="inputPassword4" className="form-label">
                  Thaluk <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className={inputhaluk()}
                  id="inputPassword4"
                  value={Taluk}
                  onChange={(e) => {
                    setTaluk(e.target.value);
                    setTalukValid(e.target.value !== "");
                  }}
                  style={{ borderColor: TalukValid ? "initial" : "red" }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6   col-sm-6  mb-3">
                <label className="form-label">
                  District <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="district"
                  value={district}
                  onChange={handleDistrictChange}
                  style={{ borderColor: districValid ? "initial" : "red" }}
                  required
                >
                  <option value="" disabled>
                    Select a district
                  </option>
                  {options
                    .slice() // Create a copy of the array to avoid modifying the original
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                    .map((option) => (
                      <option key={option.id} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="form-label">
                  GSTIN <span style={{ color: "red" }}>*</span>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inputZip"
                    value="gstNo"
                    onChange={handleRadioChange}
                    required
                  />
                  <label className="form-check-label">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inputZip"
                    value="option2"
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label">No</label>
                </div>
                {showGSTINInput && (
                  <div>
                    <input
                      type="text"
                      className={inputgst()}
                      id="inputZip"
                      value={gstNo}
                      onChange={(e) => {
                        setGstNo(e.target.value);
                        setgstValid(e.target.value !== "");
                      }}
                      style={{ borderColor: gstvalid ? "initial" : "red" }}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <label for="inputZip" className="form-label">
                  Company Started Year <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className={inputyear()}
                  id="inputZip"
                  placeholder="YYYY"
                  value={companyYear}
                  onChange={(e) => {
                    const inputYear = parseInt(e.target.value);
                    if (!isNaN(inputYear) && e.target.value.length === 4) {
                      setCompanyyear(inputYear.toString());
                      setcompanyValid(true);
                    } else {
                      setCompanyyear(e.target.value);
                      setcompanyValid(false);
                    }
                  }}
                  style={{ borderColor: companyvalid ? "initial" : "red" }}
                  required
                  maxLength={4}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <label for="inputZip" className="form-label">
                  Company Location <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className={inputlocation()}
                  id="inputZip"
                  value={locationValue}
                  onChange={(e) => {
                    setLocationValue(e.target.value);
                    setlocationValid(e.target.value !== "");
                  }}
                  style={{ borderColor: locationvalid ? "initial" : "red" }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-3 col-md-6 col-sm-6 mb-3">
                <label for="inputZip" className="form-label">
                  No of Permanent Employee{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="tel"
                  className={inputpermanent()}
                  id="inputZip"
                  value={employee}
                  onChange={(e) => {
                    setEmployee(e.target.value);
                    setEmployeeValid(e.target.value !== "");
                  }}
                  style={{ borderColor: employeevalid ? "initial" : "red" }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <label for="inputZip" className="form-label">
                  No Of Employee Temporary{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="tel"
                  className={inputemporary()}
                  id="inputZip"
                  value={employeeTemp}
                  onChange={(e) => {
                    setEmployeeTemp(e.target.value);
                    setEmployeeValidTemp(e.target.value !== "");
                  }}
                  style={{ borderColor: employeevalidTemp ? "initial" : "red" }}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                {/* <label className="form-label"> </label>

                <div className="text-dark">
                  <Multiselect
                    isObject={false}
                    onRemove={(event) => {
                      console.log(event);
                    }}
                    onSelect={selectAllOptions}
                    options={data}
                    showCheckbox
                    value={services}
                  />
                </div> */}
                <label className="form-label">
                  Services <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={optionList}
                  placeholder="Select Services"
                 value={optionList.filter((option) =>
                    selectedService.includes(option.label)
                  )}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <div
                  className="form-check form-check-inline"
                  style={{ marginTop: "11%" }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOption"
                    id="inlineRadio1"
                    value="Ownshop"
                    checked={selectedOption === "Ownshop"}
                    onChange={handleRadioChange1}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    Ownshop
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOption"
                    id="inlineRadio2"
                    value="Rentshop"
                    checked={selectedOption === "Rentshop"}
                    onChange={handleRadioChange1}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    Rentshop
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-3 col-md-6 col-sm-6 mb-3">
                <label className="form-label" for="file2">
                  Payment Screenshot
                </label>
                <input
                  type="file"
                  name="file"
                  multiple
                  className="form-control"
                  accept=".jpg, .jpeg, .png,"
                  onChange={handleFileChange2}
                  style={{ borderColor: fileValid ? "initial" : "red" }}
                  required
                />
                {!fileValid && (
                  <div className="invalid-feedback">
                    File size must be less than or equal to 800KB.
                  </div>
                )}
                <h6>(Registration fees per member Rs:1000)</h6>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <label for="inputZip" className="form-label">
                  UPI ID
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputZip"
                  value="eazypay.ntb1100078322@icici"
                  style={{ borderColor: employeevalidTemp ? "initial" : "red" }}
                  required
                />
              </div>
             
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3 card">
                <h4 style={{ textAlign: "center", paddingTop: "5px" }}>
                  Account Details
                </h4>

                <label for="inputZip" className="form-label">
                  Bank Name:Central Bank Of India <br />
                  Account No : 1855686781 <br />
                  IFSC Code : CBIN0283095 <br />
                  <hr />
                  Bank Name :ICICI Bank <br />
                  Account No : 605301208303 <br />
                  IFSC Code : ICIC0006053 <br />
                </label>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3 d-flex justify-content-center">
                <img src={qr} alt="logo" className="image-class-1" />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <button
              type="submit"
              className="sub-btn-member"
              onClick={(e) => handleSubmit(e)}
            >
              Submit{" "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OnlineRegister;
