import React from "react";
import OnlineRegister from './OnlineRegister/OnlineRegister' ;
import Login from  './Login/Login';
import SideBar from './SideBar/SideBar';
import Member from './Member/Member';
import ApproveMember from './ApproveMember/ApproveMember';
import VerifyMember from './VerifyMember/VerifyMember';
import ViewMember from './ViewMember/ViewMember';
import MemberDetails from './MemberDetails/MemberDetails';
import DashBoard from './DashBoard/DashBoard';
import Report from "./Report/Report";
import Printpreview from "./print/Printpreview";
import PrinterData from "./Printer/Printer";

import { BrowserRouter, Route, Routes  } from "react-router-dom";
import Payment from "./Payment/Payment";
function App() {
  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Login />}></Route>
        <Route path="/THGOA" element={<OnlineRegister />}> </Route>
        <Route path="/SideBar" element={<SideBar />}>
        <Route path="/SideBar/DashBoard" element={<DashBoard />}></Route>
         <Route path="/SideBar/Member" element={<Member />}></Route>
        <Route path="/SideBar/ApproveMember" element={<ApproveMember />}></Route>
        <Route path="/SideBar/Report" element={<Report />}></Route>
        <Route path="/SideBar/ApproveMember/VerifyMember" element={<VerifyMember />}></Route>
        <Route path="/SideBar/ViewMember" element={<ViewMember />}></Route>
         <Route path="/SideBar/ViewMember/MemberDetails" element={<MemberDetails />}></Route>
         <Route path="/SideBar/Payment" element={<Payment />}></Route>
         <Route path="/SideBar/Printer" element={<PrinterData />}></Route>
        <Route path="/SideBar/Printpreview" element={<Printpreview/>}></Route>
         </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
