import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { FaFileDownload } from "js-file-download";
import "./VerifyMember.css";
import {
  FaArrowRight,
  FaCheck,
  FaClosedCaptioning,
  FaDownload,
  FaLink,
  FaTimes,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "bootstrap";
const VerifyMember = () => {
  const location = useLocation();
  const data = location.state.data.company_id;
  // const date = location.state.data.modifiedDate;

  const [isClicked, setIsClicked] = useState(false);

  const [filter, setFilter] = useState([]);
  const [image, setImage] = useState([]);
  console.log({ filter: image });
  const [memberId, setMemberId] = useState("");
  // const paidAmount = "1000";

  const download =  async (e) => {

    e.preventDefault();

    try {
      const response = await axios.get(`https://fsrvr1.com/falcon_thgoa/images/${image}`, {
        responseType: "blob"
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "download.jpg");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://fsrvr1.com/falcon_thgoa/user2");
        const res = await req.json();

        const filteredData = res.filter((item) => item.company_id === data);
        console.log("status", filteredData);
        setFilter(filteredData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://fsrvr1.com/falcon_thgoa/user1");
        const res = await req.json();

        const image_details = res.filter((item) => item.company_id === data);
        //console.log("dada0",res[0].payment_ss)
         console.log("status", image_details[0].payment_ss);
        setImage(image_details[0].payment_ss);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const currentDate = new Date();
    const originalDate = currentDate.getDate(); // current date
    const originalMonth = currentDate.getMonth() + 1; // current month
    const originalYear = currentDate.getFullYear(); // current year

    setCurrentdate(`${originalDate}-${originalMonth}-${originalYear}`);

    const modifiedDate = new Date(currentDate);
    modifiedDate.setDate(modifiedDate.getDate() + 365);
    //  modifiedDate.setDate(modifiedDate.getDate() - 358);

    const modifiedDateResult = modifiedDate.getDate();
    const modifiedMonthResult = modifiedDate.getMonth() + 1;
    const modifiedYearResult = modifiedDate.getFullYear();

    // console.log({
    //   modifiedDate: `${modifiedDateResult}-${modifiedMonthResult}-${modifiedYearResult}`
    // });
    setmodifiedDate(
      `${modifiedDateResult}-${modifiedMonthResult}-${modifiedYearResult}`
    );
  }, []);

  const [currentdate, setCurrentdate] = useState("");
  const [modifiedDate, setmodifiedDate] = useState("");
  const navigate = useNavigate();

  const handleUpdate1 = async (id, member_due_date) => {
    console.log({ date: member_due_date });
    const member_status1 = 2;
    const response = await fetch(
      `https://fsrvr1.com/falcon_thgoa/update?id=${id}&member_due_date=${modifiedDate}&member_status=${member_status1}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    navigate("/Sidebar/ApproveMember", {
      member_status: { member_status: 0, member_due_date: member_due_date },
    });
  };

  const handleUpdate = async (id, member_due_date, member_status) => {
    console.log({ member_due_date: member_due_date, id, member_status });
    try {
      const member_status = 1;
      const response = await fetch(
        `https://fsrvr1.com/falcon_thgoa/update?id=${id}&member_due_date=${modifiedDate}&member_status=${member_status}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log(response);
        navigate("/Sidebar/ApproveMember");
      } else {
        console.error("Payment submission failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsClicked(true);
  };



  const handleDownloadClick = (url) =>{
    // const byteCharacters = atob(image);
    // const byteNumbers = new Array(byteCharacters.length);
  
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
  
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], { type: 'image/jpeg' });
  
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(blob);
    // link.download = 'image.jpg';
    // link.click();
    console.log(url)
    const filename = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href= url;
    aTag.setAttribute("download", filename);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }
  
  

  return (
    <div className="p-4">
      <div className="">
        <div className="row m-0">
          <div className="col-md-12 card-member">
            <div className="table-container mt-3">
              <table>
                <thead>
                  <tr>
                    <th>Member id</th>
                    <th>Member Name</th>
                    {/* <th>Member Image</th> */}
                    <th>Approve</th>
                    {image && (
                    <th> Payment ScreenShot</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filter.map((item) => {
                    return (
                      <tr key={item.member_id}>
                        <td>{item.member_id}</td>
                        <td>{item.member_name}</td>
                        {/* <td>
                          <img
                            src={`https://fsrvr1.com/falcon_thgoa/images/${item.member_image}`}
                            alt="Member Image"
                          />
                        </td> */}
                        <td className="">
                          {item.member_status == 0 && (
                            <>
                              <button
                                className={"g-button me-3"}
                                onClick={() => handleUpdate(item.member_id)}
                              >
                                <FaCheck />
                              </button>
                              <button
                                className={"r-button"}
                                onClick={() => handleUpdate1(item.member_id)}
                              >
                               <FaTimes />
                              </button>
                            </>
                          )}

                          {item.member_status == 1 && (
                            <button className={"sub-btn"}>
                              <FaCheck />
                            </button>
                          )}

                          {item.member_status == 2 && (
                            <button className={"sub-btn1 m-1"} disabled>
                              <FaTimes />
                            </button>
                          )}
                        </td>
                        <td>
                          {image  && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              {/* <h3>Download Payment ScreenShot</h3> */}
                              click the below link
                              <button className="btnpay"
                                // href={`https://fsrvr1.com/falcon_thgoa//images/${image}`}
                                // download
                                onClick={(e) =>  download(e)}
                              >
                                <FaDownload />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMember;
