import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCheck, FaEye, FaFileExcel, FaSearch, FaTimes } from "react-icons/fa";
import "./ApproveMember.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ApproveMember = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const member_status = location.state?.member_status;

  const selected = location.status;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [productData, setProductData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [result, setResult] = useState([]);

  const getData = () => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((res) => setResult(res));
  };

  const handleSubmit = (company_id, payment) => {
    console.log("Pay", payment);
    if (company_id) {
      navigate("/SideBar/ApproveMember/VerifyMember", {
        state: { data: company_id },
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://fsrvr1.com/falcon_thgoa/user1");
        const res = await req.json();
        setProductData(res);
        setFilter(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getData();
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const req = await fetch("https://fsrvr1.com/falcon_thgoa//user2");
  //       const res = await req.json();
  //       setFilter(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const result = productData.filter((item) => {
      return item.company_name.toLowerCase().includes(inputValue.toLowerCase());
    });
    setFilter(result);
  };
  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  return (
    <div className="p-4">
      <div className="row m-0">
        <div className="col-md-12 card-member">
          <div className="col-md-9 d-flex align-items-center">
            <h1 className="head-fon-mv">Approve Members</h1>
          </div>
          <div className="row">
            <div className="items-per-page col-md-9 d-flex align-items-center p-3">
              <label>Show&nbsp;</label>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              <label>&nbsp;entries</label>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-end">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
          </div>
          <div className={query.length ? "searchshow" : ""}>
            {query.length > 0 &&
              filter.map((pdata, index) => <p key={index}>{pdata.title}</p>)}
          </div>
          <div className="table-container text-end mt-3">
            <div className="">
              <FaFileExcel className="m-1 export-btn" />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button export mb-3"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export to Excel "
              />
            </div>
            <table id="table-to-xls">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>s.no</th>
                  <th>Company Id</th>
                  <th>Company Name</th>
                  <th>District</th>
                  <th style={{ width: "5%" }}>Entry</th>
                  {/* <th>Accepted members</th> */}
                  <th style={{ width: "5%" }}>View</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((member_details, index) => (
                  <tr key={index}>
                    <td>
                      {startIndex + index + 1} {console.log(member_details)}
                    </td>
                    <td>{member_details.company_id}</td>
                    <td>{member_details.company_name}</td>
                    <td>{member_details.company_distric}</td>
                    <td>
                      <span
                        className={
                          member_details.entery === "Online"
                            ? "paid green"
                            : member_details.entery === "Offline"
                            ? "unpaid red"
                            : "unpaid"
                        }
                      >
                        {member_details.entery === "Online"
                          ? "Online"
                          : member_details.entery === "Offline"
                          ? "Offline"
                          : "Other Status"}{" "}
                      </span>
                    </td>

                    <td>
                      <span
                        className="submit"
                        onClick={() =>
                          handleSubmit({
                            company_id: member_details.company_id,
                            Payment: member_details.payment_ss,
                          })
                        }
                      >
                        <FaEye className="icon" />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ApproveMember;
