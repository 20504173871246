

import React from "react";
import { Component } from "react";


import ReactToPrint from "react-to-print";

class PrinterData extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }



  

  render(){
    return(
    <div>
          <ReactToPrint
      
          trigger={()=> {
      
             return <button >Print</button>
      
          }}
          content={() =>this.componentRef}
          
          
          />


         <div ref={(el) => (this.componentRef = el)}>
        <h2 style={{color: "black"}}>Attendance</h2>
        <table>
          <thead>
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Njoku Samson</td>
              <td>samson@yahoo.com</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ebere Plenty</td>
              <td>ebere@gmail.com</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Undefined</td>
              <td>No Email</td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
    )
  }
}

export default PrinterData;

