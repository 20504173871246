import React, { useState } from "react";
import {
  FaBars,
  FaTh,
  FaUserPlus,
  FaUser,
  FaSignOutAlt,
  FaShoppingBag,
  FaPaypal,
  FaUserAltSlash,
  FaStreetView,
  FaRegEyeSlash,
  FaBullseye,
  FaEye,
  FaUserEdit,
  FaCheck,
  FaReply,
  FaPrint,
} from "react-icons/fa";
import { NavLink, Outlet } from "react-router-dom";

import "./SideBar.css";

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: "/SideBar/DashBoard",
      name: "DashBoard",
      icon: <FaTh />,
    },
    {
      path: "/SideBar/Member",
      name: "Add Member",
      icon: <FaUserPlus />,
    },
    {
      path: "/SideBar/ApproveMember",
      name: "Approve Members",
      icon: <FaCheck className="user-tick-icon" />,
    },
    {
      path: "/SideBar/ViewMember",
      name: "View Member",
      icon: <FaEye />,
    },
    {
      path: "/SideBar/Payment",
      name: "Payment",
      icon: <FaPaypal />,
    },
    {
      path: "/SideBar/Report",
      name: "Report",
      icon: <FaReply />,
    },
    {
      path: "/SideBar/Printpreview",
      name: "Printpreview",
      icon: <FaPrint />,
    },
    {
      path: "/",
      name: " Logout",
      icon: <FaSignOutAlt />,
    },
  ];

  return (
    <div className="container_dash">
      <div style={{ width: isOpen ? "220px" : "50px" }} className="sidebar">
        <div className="top_section">
          <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
            THGOA
          </h1>
          <div className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            exact
            to={item.path}
            key={index}
            className="link"
            activeClassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none", marginTop: "3px" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>

      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default SideBar;
