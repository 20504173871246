import React, { useState, useEffect } from "react";
import { FaSearch, FaPrint } from "react-icons/fa";
import "./printpreview.css";
import { logDOM } from "@testing-library/react";

function Printpreview() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [productdata1, setProductdata1] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch("https://fsrvr1.com/falcon_thgoa/printpreview");
      const res = await response.json();
      setData(res);
      setFilter(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchData1 = async () => {
    try {
      const req = await fetch("https://fsrvr1.com/falcon_thgoa/user1");
      const res = await req.json();
      setProductdata1(res);
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleInput = (e) => {
    const inputValue = e.target.value;
    console.log("name", inputValue);
    setQuery(inputValue);
    // setRefresh(!refresh);
    const result = data.filter((item) => {
      const companyNameMatch = item.member_id
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const memberNameMatch = item.member_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const companyNameMatch1 = item.company_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const companyMemberId = item.phone_number
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      return (
        memberNameMatch ||
        companyNameMatch ||
        companyMemberId ||
        companyNameMatch1
      );
    });
    setFilter(result);
  };

  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);

  const startIndex = 0;

  const handleCheckboxChange = (id) => {
    const newSelectedRows = [...selectedRows];
    const index = newSelectedRows.indexOf(id);
    if (index === -1) {
      newSelectedRows.push(id);
    } else {
      newSelectedRows.splice(index, 1);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    const allRowsIds = Filter.map((item) => item.id);
    if (selectedRows.length === allRowsIds.length) {
      // If all rows are already selected, clear selection
      setSelectedRows([]);
    } else {
      // Otherwise, select all rows
      setSelectedRows(allRowsIds);
    }
  };

  const handlePrint = () => {
    const printContent = selectedRows.map((id) => {
      const row = data.find((item) => item.id === id);
      const companyAddress = (productdata1.find((member) => member.id === id)?.company_address) || '';

  
      return `
        <div key=${row.id} style="display: flex; align-items: center; border-radius: 8px;">
          <div class="row" style="display:flex; width:100%;">  
            <div style="margin-right: 0px; display:flex; border: 1px solid #ccc; width:50%; margin:5px; padding:15px;">
              <div style="width:50%; display:flex; justify-content:center; flex-direction:column; align-items:center;">
                ${row.member_image &&
                `<img style="width:200px; height:200px; object-fit:cover; max-width: 100px; max-height: 100px; border-radius: 50%;" src="https://fsrvr1.com/falcon_thgoa/images/${row.member_image}" alt="Member Image" />`}
                <p style="font-size: 16px; margin-bottom: 5px;"> ${row.member_id}</p>
                <p style="font-size: 16px; margin-bottom: 5px;">Valid Till: ${row.member_due_date}</p>
              </div>
              <div style="width:50%; display:flex; justify-content:center; align-items:flex-start; flex-direction:column;">
                <p style="font-size: 16px; margin-bottom: 5px;">Member Name: ${row.member_name}</p>
                <p style="font-size: 16px; margin-bottom: 5px;">Company Name: ${row.company_name}</p>
                <p style="font-size: 16px; margin-bottom: 5px;">Phone No: ${row.phone_number}</p>
              </div>
            </div>
            <div style=" width:50%; margin:5px; border:1px solid #ccc; padding:15px;">
              <p style="font-size: 16px; margin-bottom: 5px;">Alter Number: ${row.alter_number}</p>
              <p style="font-size: 16px; margin-bottom: 5px;">Company Address: ${companyAddress}</p>
              <p style="font-size: 16px; margin-bottom: 5px;">DOB: ${row.dob}</p>
              <p style="font-size: 16px; margin-bottom: 5px;">Blood Group: ${row.blood_group}</p>
            </div>
          </div>
        </div>
      `;
    });
  
    if (printContent.length === 0) {
      alert("Please select members to print");
      return;
    }
  
    const previewWindow = window.open("", "_blank");
    if (!previewWindow) {
      console.error("Unable to open preview window");
      return;
    }
    const printButton = previewWindow.document.getElementById("printButton");
    if (printButton) {
      printButton.style.display = "none";
    }
    
    previewWindow.document.write(`
      <html>
        <head>
          <title>Print Preview</title>
        </head>
        <body>
          ${printContent.join("")}
          <button onclick="window.print()">Print</button>
        </body>
      </html>
    `);
  
    previewWindow.document.close();
  };
  

  // Button to trigger the print preview
  <button onClick={handlePrint}>Print Preview</button>;

  return (
    <div className="container">
      {loading && <p>Loading...</p>}
      {!loading && (
        <>
          <div className="row mt-3">
            <div className="col-md-3 d-flex align-items-center justify-content-end">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => handleInput(e)}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <label className="mr-3">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedRows.length === data.length}
                  className="m-2"
                />
                Select All
              </label>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-end">
              <button onClick={handlePrint} className="btn-print">
                <FaPrint className="m-1" /> Print Selected
              </button>
            </div>

            <table className="table mt-3">
              <thead>
                <tr>
                  <th>
                    {/* <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedRows.length === data.length}
                    /> */}
                  </th>
                  <th>s.no</th>
                  <th>Member Name</th>
                  <th>Company Name</th>
                  <th>Phone No</th>
                  <th>Member Id</th>
                  <th>Due Date</th>
                  <th>Alter Number</th>
                  <th>Company Address</th>
                  <th>DOB</th>
                  <th>Blood Group</th>
                  <th>Member Image</th>
                </tr>
              </thead>
              <tbody>
                {Filter.map((member_details, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(member_details.id)}
                        checked={selectedRows.includes(member_details.id)}
                      />
                    </td>
                    <td>{startIndex + index + 1}</td>
                    <td>{member_details.member_name}</td>
                    <td>{member_details.company_name}</td>
                    <td>{member_details.phone_number}</td>
                    <td>{member_details.member_id}</td>
                    <td>{member_details.member_due_date}</td>
                    <td>{member_details.alter_number}</td>
                    <td>
                      {
                        productdata1.find(
                          (member) => member.id === member_details.id
                        )?.company_address
                      }
                    </td>
                    <td>{member_details.dob}</td>
                    <td>{member_details.blood_group}</td>
                    <td>
                      {member_details.member_image && (
                        <img
                          src={`https://fsrvr1.com/falcon_thgoa/images/${member_details?.member_image}`}
                          alt="Member Image"
                          className="img-thumbnail"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default Printpreview;
